<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Klienci</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj nowego
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.first_name"
                            label="Imię"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.last_name"
                            label="Nazwisko"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Nr telefonu"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.email"
                            label="Adres email"
                          ></v-text-field>
                        </v-col>
                        <v-switch v-model="itemHasCompany" label="firma"></v-switch>
                        <v-col v-if="itemHasCompany">
                          <v-text-field
                            v-model="editedItem.company.company_name"
                            label="Nazwa firmy"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.company.tax_id"
                            label="NIP"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.company.street"
                            label="Adres"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.company.postcode"
                            label="Kod pocztowy"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.company.city"
                            label="Miejscowość"
                          ></v-text-field>
                        </v-col>
                      </v-form>
                      <v-row>
                        <v-col>

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Anuluj
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="generate"
                    >
                      Dodaj
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.first_name="{ item }">
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <!-- <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios';
import store from '@/store'
export default {

    data: () => ({
      url: store.getters.url,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Imię i nazwisko', value: 'first_name' },
        { text: 'Email', value: 'email' },
        { text: 'Numer telefonu', value: 'phone' },
        {
          text: 'Data wygenerowania',
          align: 'start',
          sortable: true,
          value: 'date_created',
        },
        // { text: 'Akcje', value: 'actions' },
      ],
      items: [],
      editedIndex: -1,
      itemHasCompany: true,
      editedItem: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        company: {
          company_name: '',
          tax_id: '',
          postcode: '',
          city: '',
          street: ''
        }
      },
      defaultItem: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        company: {
          company_name: '',
          tax_id: '',
          postcode: '',
          city: '',
          street: ''
        }
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Dodaj klienta' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios({
          url: `${this.url}/customers/get`,
          data: {},
          method: 'POST'
        })
        .then( resp => {
          if( resp.status == 200 ){
            this.items = resp.data.list;
          }
        })
      },

      generate() {

        axios({
          url: `${this.url}/customers/create`,
          data: this.editedItem,
          method: 'POST'
        })
        .then( resp => {
          if( resp.status == 200 ){
            this.initialize();
          }
          this.close();
        })
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },


    },
  }
</script>
