<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Projekty</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj nowy
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.name"
                            label="Nazwa projektu"
                          ></v-text-field>
                          <v-autocomplete
                            v-model="editedItem.customer_id"
                            :items="customers"
                            :loading="isLoading"
                            :search-input.sync="search"
                            color="black"
                            hide-no-data
                            hide-selected
                            item-text="Description"
                            item-value="ID"
                            label="Wybierz klienta"
                            prepend-icon="mdi-database-search"

                            @click="findCustomer"
                          ></v-autocomplete>
                          <v-select
                            v-model="editedItem.type"
                            :items="types"
                            item-text="label"
                            item-value="value"
                            label="Wybierz typ"
                          ></v-select>
                          <v-select
                            v-model="editedItem.source"
                            :items="sources"
                            item-text="label"
                            item-value="value"
                            label="Wybierz źródło"
                          ></v-select>
                          <v-select
                            v-model="editedItem.status"
                            :items="statuses"
                            item-text="label"
                            item-value="value"
                            label="Wybierz statis"
                          ></v-select>
                        </v-col>

                      </v-form>
                      <v-row>
                        <v-col>

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Anuluj
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="generate"
                    >
                      Dodaj
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.customer="{ item }">
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <!-- <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios';
import store from '@/store'
export default {

    data: () => ({
      url: store.getters.url,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Nazwa', value: 'name' },
        { text: 'Klient', value: 'customer' },
        { text: 'Źródło', value: 'source' },
        { text: 'Typ projektu', value: 'type' },
        { text: 'Status', value: 'status' },
        {
          text: 'Data wygenerowania',
          align: 'start',
          sortable: true,
          value: 'date_created',
        },
        // { text: 'Akcje', value: 'actions' },
      ],
      items: [],
      editedIndex: -1,
      isLoading: false,
      sources: [
        {
          value: 'organic',
          label: 'Organiczne'
        },
        {
          value: 'email_marketing',
          label: 'Kampania mailowa'
        },
        {
          value: 'cpc_google',
          label: 'Kampania Google ADS'
        },
        {
          value: 'cpc_facebook',
          label: 'Kampania Facebook'
        },
        {
          value: 'facebook_group',
          label: 'Grupa Facebook'
        },
        {
          value: 'ave_studio',
          label: 'Ave Studio'
        },
        {
          value: 'rebrand',
          label: 'Rebrand'
        },
        {
          value: 'adrian_melka',
          label: 'Adrian Melka'
        },
      ],
      types: [
        {
          value: 'website',
          label: 'Strona WWW'
        },
        {
          value: 'app',
          label: 'Aplikacja'
        },
        {
          value: 'graphics',
          label: 'Prace graficzne'
        },
        {
          value: 'video',
          label: 'Obróbka video'
        },
        {
          value: 'marketing',
          label: 'Marketing'
        },
      ],
      statuses: [
        {
          value: 'new',
          label: 'Nowe'
        },
        {
          value: 'pending',
          label: 'W trakcie'
        },
        {
          value: 'finished',
          label: 'Zakończone'
        }
      ],
      editedItem: {
        name: '',
        customer_id: 0,
        type: 'website',
        source: 'organic',
        status: 'new'
      },
      defaultItem: {
        name: '',
        customer_id: 0,
        type: 'website',
        source: 'organic',
        status: 'new'
      },
      search: null,
      customer_entries: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nowy projekt' : 'Edit Item'
      },
      customers () {
        return this.customer_entries.map(entry => {
          const Description = `${ entry.first_name } ${ entry.last_name } (${ entry.email })`
          const ID = entry.customer_id;
          return Object.assign({}, entry, { Description, ID })
        })
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search(val) {
        if( this.customers.length > 0 ) return;

        if(this.isLoading ) return;

        this.isLoading = true;

        axios({
          url: `${ this.url }/customers/get`,
          data: {
            params: {
              first_name: val,
              last_name: val,
              email: val
            }
          },
          method: 'POST'
        })
        .then( resp => {

          this.customer_entries = resp.data.list

        })
        .catch()
        .finally(() => (this.isLoading = false))

      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios({
          url: `${this.url}/projects/get`,
          data: {},
          method: 'POST'
        })
        .then( resp => {
          if( resp.status == 200 ){
            this.items = resp.data.list;
          }
        })
      },

      generate() {

        axios({
          url: `${this.url}/projects/create`,
          data: this.editedItem,
          method: 'POST'
        })
        .then( resp => {
          if( resp.status == 200 ){
            this.initialize();
          }
          this.close();
        })
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },

      findCustomer() {
        if( this.search === null ) this.search = ''
      }


    },
  }
</script>
